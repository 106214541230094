import React from "react";
import { graphql } from "gatsby";
import ModuleZone from "~moduleZone";
import Layout from "@components/layout";
import { PageMeta } from "@components/pageMeta";
import IntroBlock from "@components/other/introBlock";
import Container from "@components/financeForm/businessHomeLoans/container";
import FastContainer from "@components/financeForm/fastApproval/fastProviderContainer";

const Apply = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data?.page;
  const { modules, seo, title, subtitle } = page || {};

  return (
    <Layout reversedHeader={true}>
      <PageMeta {...seo} />
      <IntroBlock title={title} subtitle={subtitle} />

      {modules && <ModuleZone {...modules} />}
      <Container data={data} />
      <FastContainer data={data} />
    </Layout>
  );
};

export default Apply;

export const pageQuery = graphql`
  query sanityApplyPageQuery {
    page: sanityApplyPage {
      title
      subtitle
      seo {
        ...SEO
      }
      modules {
        ...ApplyModules
      }
    }
    sanityHomeLoans {
      contactMethodDropdownOptions
      fundingDropdownOptions
      marketValueDropdownOptions
      regionDropdownOptions
      requiredByDropdownOptions
      requiredForDropdownOptions
      propertyTypeDropdownOptions
    }
    sanityBusinessLoans {
      contactMethodDropdownOptions
      financeTypeOptions
      industryDropdownOptions
      preferredPaymentDropdownOptions
      regionDropdownOptions
      requiredByDropdownOptions
      solutionTypeOptions
    }
    sanityFastLoans {
      alreadyPurchasedOptions
      businessClearCreditOptions
      contactMethodDropdownOptions
      directorsClearCreditOptions
      directorsPropertyOwners
      financeRequiredBy
      gstFundedOptions
      industryOptions
      isDirectorOptions
      legalStructureOptions
      positionOptions
      regionDropdownOptions
      repaymentOptions
      typeOfAssetOptions
      yearsOfOperationOptions
    }
  }
`;
